import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AdminService } from '../services/admin.service';
import * as _ from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  globals: any;
  state: any;

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.globals.userId = localStorage.getItem('druuid');
    if (this.globals.selectedCampaign !== '') {
      this.getCampaign();
    } else {
      this.state = 0;
    }
  }

  getCampaign() {
    this.globals.loading = true;
    this.service.getCampaign(this.globals.selectedCampaign).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.campaign = data[0];
        if (data[0] = 2) {
          this.state = 1;
          this.globals.loading = true;
          setTimeout(() => {
            this.loadData();
          }, 2000);
        } else {
          this.state = this.globals.campaign.state;
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  loadData() {
    let d = this.globals.campaign;
    for (const f of d.formats) {
      if (f.comments) {
        (document.getElementById(`textAreaFormat${f._id}`) as HTMLInputElement).value = f.comments;
      }
      if (f.days) {
        for (const d of f.days) {
          for (const k of f.keys) {
            (document.getElementById(`date${d.id}`) as HTMLInputElement).value = d.date;
            (document.getElementById(`dayData${f._id}${d.counter}${k.id}`) as HTMLInputElement).value = d[k.id];
          }
        }
      }
      if (f.creatives) {
        for (const c of f.creatives) {
          if (c.file.match('.jpeg') || c.file.match('.png') || c.file.match('.jpg')) {
            c.type = 'image'
          }
          if (c.file.match('.mp4') || c.file.match('.mov')) {
            c.type = 'video'
          }
          if (c.file.match('.mp3') || c.file.match('.wav')) {
            c.type = 'audio'
          }
          for (const dd of c.days) {
            (document.getElementById(`creativeDateday${dd.id}${c.selector}`) as HTMLInputElement).value = dd.date;
            for (const k of f.keys) {
              (document.getElementById(`creativeday${dd.id}${c.selector}${k.id}`) as HTMLInputElement).value = dd[k.id];
            }
          }
        }
      }
      if (f.prints) {
        for (const c of f.prints) {
          if (c.file.match('.jpeg') || c.file.match('.png') || c.file.match('.jpg')) {
            c.type = 'image'
          }
          if (c.file.match('.mp4') || c.file.match('.mov')) {
            c.type = 'video'
          }
          if (c.file.match('.mp3') || c.file.match('.wav')) {
            c.type = 'audio'
          }
        } 
      }
    }
    this.globals.loading = false;
  }

  saveOne() {
    let g = this.globals.campaign;
    let campaign = {
      name: g.name,
      company: g.company,
      state: 1,
      brand: g.brand,
      billing: g.billing,
      response: g.response,
      country: g.country,
      currency: g.currency,
      trm: g.trm,
      symbol: '$',
      formats: g.formats,
      code: g.code
    }
    this.globals.loading = true;
    this.service.saveStepOne(campaign).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.router.navigate(['/home']);
        return Swal.fire('', `Campaña configurada correctamente`, 'success');
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }


  ver() {
    let f = this.globals.campaign.formats;
    for (const ff of f) {
      for (const c of ff.creatives) {
        c.days = [];
        for (const d of ff.days) {
          let day: any = {};
          day.id = `creative${ff._id}${d.counter}`;
          day.date = (document.getElementById(`creativeDate${ff._id}${d.counter}`) as HTMLInputElement).value;
          if (day.date !== '') {
            for (const k of ff.keys) {
              if (day[k.id] !== '') {
                day[k.id] = parseFloat((document.getElementById(`creative${ff._id}${d.counter}${k.id}`) as HTMLInputElement).value);
              } else {
                return Swal.fire('', 'Uno o varios campos estan vacios revise antes de guardar', 'error');
              }
            }
          } else {
            return Swal.fire('', 'las fechas de los creativos son obligatorias', 'error');
          }
          c.days.push(day);
        }
      }
    }
  }

  saveTwo() {
    let f = this.globals.campaign.formats;
    for (const ff of f) {
      ff.comments = (document.getElementById(`textAreaFormat${ff._id}`) as HTMLInputElement).value;
      if (ff.days) {
        for (const d of ff.days) {
          d.date = (document.getElementById(`date${d.id}`) as HTMLInputElement).value;
          if (d.date !== '') {
            for (const k of ff.keys) {
              if (d[k.id] !== '') {
                d[k.id] = parseFloat((document.getElementById(`dayData${ff._id}${d.counter}${k.id}`) as HTMLInputElement).value);
              } else {
                return Swal.fire('', 'Uno o varios campos estan vacios revise antes de guardar', 'error');
              }
            }
          } else {
            return Swal.fire('', 'las fechas son obligatorias', 'error');
          }
        }
      }
      if (ff.creatives) {
        for (const c of ff.creatives) {
          c.days = [];
          // if (c.file !== '') {

          // } else {
          //   return Swal.fire('', 'los creativos son obligatorias', 'error');
          // }
          for (const d of ff.days) {
            let day: any = {};
            day.id = `${ff._id}${d.counter}`;
            day.date = (document.getElementById(`creativeDate${d.id}${c.selector}`) as HTMLInputElement).value;
            for (const k of ff.keys) {
              if (day[k.id] !== '') {
                day[k.id] = parseFloat((document.getElementById(`creative${d.id}${c.selector}${k.id}`) as HTMLInputElement).value);
              } else {
                return Swal.fire('', 'Uno o varios campos estan vacios revise antes de guardar', 'error');
              }
            }
            c.days.push(day);
          }
        }
      }
    }
    let g = this.globals.campaign;
    let campaign = {
      _id: this.globals.selectedCampaign,
      state: 2,
      formats: g.formats,
    }
    this.globals.loading = true;
    this.service.saveStepTwo(campaign).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.state = 2;
        return Swal.fire('', `Campaña actualizada correctamente`, 'success');
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  customer() {
    this.state = 2;
  }

  deleteImage() {
    this.globals.campaign.selectedTemplate.artistImage = '';
  }


  deleteAudio() {
    this.globals.campaign.selectedTemplate.song = '';
  }

  saveCustomer() {
    let g = this.globals.campaign;
    let campaign = {
      _id: this.globals.selectedCampaign,
      formats: g.formats,
      template: g.selectedTemplate
    }
    this.globals.loading = true;
    this.service.saveStepThree(campaign).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        // this.router.navigate(['/home']);
        return Swal.fire('', `Campaña customizada correctamente`, 'success');
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

}